<template>
  <MainLayout>
    <section class="grid grid-cols-2 gap-4">
      <div>
        <EditProfile />
        <EditPassword />
      </div>
      <div>
        <MFA />
        <WebAuthn />
      </div>
    </section>
  </MainLayout>
</template>

<script>
import MainLayout from '@/Common/MainLayout/MainLayout'
import EditPassword from '@/Pages/Profile/Components/EditPassword'
import EditProfile from '@/Pages/Profile/Components/EditProfile'
import MFA from '@/Pages/Profile/Components/MFA'
import WebAuthn from '@/Pages/Profile/Components/WebAuthn'
export default {
  name: 'Profile',
  components: {
    EditPassword,
    EditProfile,
    MFA,
    WebAuthn,
    MainLayout,
  },
}
</script>

<style></style>
