<template>
  <ContentCard title="Password" id="change-password-card">
    <ToznyForm
      class="p-4 mt-4"
      :error="showError"
      :success="changePasswordSuccessMessage"
      :loading="changePasswordLoading"
      :onSubmit="changePassword"
    >
      <ToznyInput
        class="mb-4"
        v-model="password"
        id="password"
        label="Old Password"
        type="password"
      />
      <div class="flex mb-8">
        <ToznyInput
          class="flex-grow mr-4 mb-4"
          v-model="newPassword"
          id="newPassword"
          label="New Password"
          type="password"
        />
        <ToznyInput
          class="flex-grow"
          v-model="repeatNewPassword"
          id="repeatNewPassword"
          label="Repeat New Password"
          type="password"
        />
      </div>
      <ToznyButton
        id="change-password-button"
        :class="{
          'text-gray-medium bg-white border-2 cursor-default': !formReadyToSend,
          'px-8': !changePasswordLoading,
          'px-20': changePasswordLoading,
        }"
        buttonText="Change Password"
        :loading="changePasswordLoading"
      />
    </ToznyForm>
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ContentCard from '@/Common/ContentCard'
import { validatePassword } from '@/utils/utils'

export default {
  name: 'EditPassword',
  components: {
    ToznyForm,
    ToznyInput,
    ToznyButton,
    ContentCard,
  },
  data: function () {
    return {
      password: '',
      newPassword: '',
      repeatNewPassword: '',
    }
  },
  computed: {
    ...mapState('account', [
      'changePasswordSuccessMessage',
      'changePasswordErrorMessage',
      'changePasswordLoading',
    ]),
    formReadyToSend: function () {
      return (
        !!this.password &&
        !!this.newPassword &&
        !!this.repeatNewPassword &&
        !this.passwordsDoNotMatch &&
        validatePassword(this.newPassword) === '' &&
        validatePassword(this.repeatNewPassword) === ''
      )
    },
    passwordsDoNotMatch: function () {
      return (
        this.repeatNewPassword && this.newPassword !== this.repeatNewPassword
      )
    },
    showError: function () {
      if (this.passwordsDoNotMatch) {
        return 'Passwords do not match.'
      } else if (this.passwordNotValidError) {
        return this.passwordNotValidError
      } else {
        return this.changePasswordErrorMessage
      }
    },
    passwordNotValidError: function () {
      if (this.newPassword) {
        return validatePassword(this.newPassword)
      }
      return ''
    },
  },
  /*
    If the user has already received an API error,
    the watcher will clear the error state if the
    user enters new password information.  Without this,
    the user will get past the "Passwords do not match."
    error and see an outdated API error message.
  */

  watch: {
    passwordsDoNotMatch: function (val) {
      if (val === false) {
        this.clearChangePasswordErrorMessage()
      }
    },
  },
  methods: {
    ...mapActions('account', [
      'changeAccountPassword',
      'clearChangePasswordErrorMessage',
    ]),
    clearData: function () {
      this.password = ''
      this.newPassword = ''
      this.repeatNewPassword = ''
    },
    changePassword: async function () {
      if (
        !this.formReadyToSend ||
        this.passwordsDoNotMatch ||
        this.passwordNotValidError
      ) {
        return
      } else {
        const changed = await this.changeAccountPassword({
          password: this.password,
          newPassword: this.newPassword,
        })
        if (changed) {
          this.clearData()
        }
      }
    },
  },
}
</script>
