<template>
  <ContentCard
    title="Profile"
    id="change-profile"
    data-auto-id="change-profile"
    class="change-profile"
  >
    <!-- <template #extras>
      <p class="mb-0">
        <span class="font-bold mr-2">Account ID</span>
        <span class="text-gray-dark">{{ accountId }}</span>
      </p>
    </template> -->
    <ToznyForm
      class="p-4 mt-4"
      :error="changeProfileErrorMessage"
      :success="changeProfileSuccessMessage"
      :loading="changeProfileLoading"
      :onSubmit="editUserProfile"
    >
      <label>Account ID</label>
      <p id="email" class="text-gray-dark mb-8">{{ accountId }}</p>
      <ToznyInput v-model="name" id="name" label="Name" class="mb-8" />
      <label for="email">Email</label>
      <p id="email" class="text-gray-dark mb-8">{{ accountEmail }}</p>
      <div class="flex align-center">
        <ToznyButton
          id="change-profile-button"
          class="change-profile-button mb-4 mr-4"
          :class="{
            'text-gray-medium bg-white border-2 cursor-default': !newEntry,
            'px-8': !changeProfileLoading,
            'px-12': changeProfileLoading,
          }"
          buttonText="Edit Profile"
          :loading="changeProfileLoading"
        />
      </div>
    </ToznyForm>
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapState } from 'vuex'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ContentCard from '@/Common/ContentCard'
export default {
  name: 'EditProfile',
  components: {
    ToznyForm,
    ToznyInput,
    ToznyButton,
    ContentCard,
  },
  data: function () {
    return {
      name: '',
    }
  },
  mounted: function () {
    this.name = this.accountName
  },
  computed: {
    ...mapState('account', [
      'changeProfileErrorMessage',
      'changeProfileSuccessMessage',
      'changeProfileLoading',
    ]),
    ...mapGetters('account', ['accountId', 'accountName', 'accountEmail']),
    newEntry: function () {
      return !!this.name
    },
  },
  methods: {
    ...mapActions(['setValidationError']),
    ...mapActions('account', ['editProfile']),
    editUserProfile: async function () {
      if (this.newEntry) {
        await this.editProfile({
          name: this.name || this.accountName,
        })
      } else {
        this.setValidationError('Please enter profile changes.')
      }
    },
  },
}
</script>
