<template>
  <ContentCard title="Multi-Factor Authentication">
    <Banner
      v-if="hasError && !isIdle"
      type="red"
      button-text="Reload"
      :click-method="reload"
    >
      {{ errorMessage }}
    </Banner>
    <div v-if="isIdle" class="p-4">
      <div v-if="type === 'totp'">
        <Banner
          class="inline-banner"
          button-text="Remove"
          :click-method="removeTotp"
        >
          Your account is protected with a mobile authentiator.
        </Banner>
      </div>
      <div v-else-if="!totpSet">
        <ul class="flex border-b border-gray-medium mb-4">
          <li>
            <a
              href="#!"
              class="block border border-gray-medium px-2 py-1 -mb-px mx-1 no-underline text-gray-dark"
              :class="{ active: qrTab === 'scan' }"
              @click="qrTab = 'scan'"
            >
              Scan
            </a>
          </li>
          <li>
            <a
              href="#!"
              class="block border border-gray-medium px-2 py-1 -mb-px mx-1 no-underline text-gray-dark"
              :class="{ active: qrTab === 'manual' }"
              @click="qrTab = 'manual'"
            >
              Manual
            </a>
          </li>
        </ul>
        <div>
          <div v-if="qrTab === 'scan'">
            <p>1. Scan this code in your authenticator application</p>
            <div v-html="qrCodeImg" class="mx-4 my-4 w-48 h-48"></div>
            <p>2. Enter the one-time code to finish set up</p>
            <ToznyForm
              :onSubmit="totpSetup"
              :loading="status === 'idle.submitting'"
              :error="errorMessage"
            >
              <ToznyInput
                v-model="otp"
                type="tel"
                title="Numbers only"
                pattern="[0-9]+"
                id="otp"
                name="otp"
                class="mb-6 text-lil"
                label="One-time Code"
                float-label
              />
              <ToznyButton
                id="otp-button"
                class="w-full py-3 rounded-lg text-lil mb-0"
                buttonText="Submit"
                :loading="status === 'idle.submitting'"
              />
            </ToznyForm>
          </div>
          <div v-else-if="qrTab === 'manual'">
            <p>1. Configure your app using this secret key</p>
            <dl class="mb-4 ml-4 p-0 text-lil">
              <dt class="sr-only">Secret Key:</dt>
              <dd class="mt-2 mb-4 text-base tracking-wider">
                <div class="inline-block px-2 py-1 border border-gray-medium">
                  {{ secret }}
                </div>
              </dd>
              <div v-for="conf in otpManualSetupConfig" v-bind:key="conf.title">
                <dt class="inline-block mr-2">{{ conf.title }}:</dt>
                <dd class="inline-block font-bold mb-1 inline-block">
                  {{ conf.data }}
                </dd>
              </div>
            </dl>
            <p>2. Enter the one-time code to finish set up</p>
            <ToznyForm
              :onSubmit="totpSetup"
              :loading="status === 'idle.submitting'"
              :error="errorMessage"
            >
              <ToznyInput
                v-model="otp"
                type="tel"
                title="Numbers only"
                pattern="[0-9]+"
                id="otp"
                name="otp"
                class="mb-6 text-lil"
                label="One-time Code"
                float-label
              />
              <ToznyButton
                id="otp-button"
                class="w-full py-3 rounded-lg text-lil mb-0"
                buttonText="Submit"
                :loading="status === 'idle.submitting'"
              />
            </ToznyForm>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="status === 'loading'"
      class="w-full h-32 flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-12 h-12" />
    </div>
  </ContentCard>
</template>

<script>
import Banner from '@/Common/Banner'
import ContentCard from '@/Common/ContentCard'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ToznyLoader from '@/Common/ToznyLoader'

import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'MFA',
  components: {
    Banner,
    ContentCard,
    ToznyForm,
    ToznyInput,
    ToznyButton,
    ToznyLoader,
  },
  data: function () {
    return {
      totpSet: false,
      qrTab: 'scan',
      setupType: 'totp',
      otp: '',
      poll: false,
    }
  },
  watch: {
    mfaTOTPDevices() {
      this.setMFAStatus()
    },
  },
  computed: {
    ...mapState('mfa', [
      'status',
      'errorMessage',
      'type',
      'secret',
      'policy',
      'mfaTOTPDevices',
    ]),
    ...mapState(['mfaOptions']),
    ...mapGetters('mfa', ['hasError', 'isIdle', 'qrCodeImg']),
    otpManualSetupConfig() {
      if (
        typeof this.policy !== 'object' ||
        Object.keys(this.policy).length < 1
      ) {
        return []
      }
      return [
        {
          title: 'Type',
          data: this.policy.type === 'totp' ? 'Time Based' : 'Counter Based',
        },
        {
          title: 'Algorithm',
          data: this.policy.algorithmKey,
        },
        {
          title: 'Digits',
          data: this.policy.digits,
        },
        {
          title: 'Interval',
          data: this.policy.period,
        },
      ]
    },
  },
  methods: {
    ...mapActions('mfa', ['initialize', 'setupTotp', 'reload', 'removeMFA']),
    totpSetup() {
      this.setupTotp(this.otp)
      this.otp = ''
    },
    toggleTotpSetup() {
      if (this.qrTab === 'push') {
        this.qrTab = 'scan'
        this.setupType = 'totp'
        this.mfaSwitchDisplayName = 'Push'
      } else {
        this.qrTab = 'push'
        this.setupType = 'push'
        this.mfaSwitchDisplayName = 'TOTP'
      }
    },
    setMFAStatus() {
      this.totpSet = this.mfaTOTPDevices.length > 0
    },
    removeTotp() {
      const credId = this.mfaTOTPDevices.length
        ? this.mfaTOTPDevices[0].credentialId
        : ''
      this.removeMFA(credId)
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/tailwind-variables.scss';

.active {
  border-bottom-color: #ebebeb;
  color: #2f353a;
  .dark & {
    color: #ffffff;
  }
}
.inline-banner {
  margin-bottom: 0;
}
</style>
