<template>
  <ContentCard title="Security Key Authentication">
    <div class="p-4">
      <div v-if="webAuthnSet">
        <Banner
          class="inline-banner"
          button-text="Remove"
          :click-method="deleteWebAuthn"
        >
          Your account is protected with a security device.
        </Banner>
      </div>
      <div v-else>
        <ToznyForm :onSubmit="onSubmit" :error="webauthnErrorMessage">
          <p>Enter authenticator name</p>
          <ToznyInput
            v-model="deviceName"
            id="deviceName"
            name="deviceName"
            class="mb-6 md:text-lil"
            label="Authenticator Name"
            float-label
          />
          <p>
            Press the button below to register a security key to your account
          </p>
          <ToznyButton
            id="webauthn-register-button"
            class="w-full py-3 rounded-lg text-lil mb-0"
            buttonText="Register Security Key"
            :loading="webAuthnLoading"
          />
        </ToznyForm>
      </div>
    </div>
  </ContentCard>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import Banner from '@/Common/Banner'

import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'WebAuthn',
  components: {
    ContentCard,
    ToznyForm,
    ToznyInput,
    ToznyButton,
    Banner,
  },
  data: function () {
    return {
      webAuthnLoading: false,
      webAuthnSet: false,
      deviceName: '',
      showSuccessDialog: false,
    }
  },
  watch: {
    mfaWebAuthnDevices() {
      this.webAuthnSet = this.mfaWebAuthnDevices.length > 0
    },
  },
  computed: {
    ...mapState('mfa', [
      'mfaWebAuthnDevices',
      'webauthnErrorMessage',
      'status',
    ]),
  },
  methods: {
    ...mapActions('mfa', [
      'registerWebAuthnDevice',
      'removeWebAuthn',
      'reload',
      'reset',
    ]),
    ...mapGetters('mfa', ['hasError']),
    async onSubmit() {
      this.webAuthnLoading = true
      await this.registerWebAuthnDevice(this.deviceName)
      this.deviceName = ''
      this.webAuthnLoading = false
    },
    deleteWebAuthn() {
      const credId = this.mfaWebAuthnDevices.length
        ? this.mfaWebAuthnDevices[0].credentialId
        : ''
      this.removeWebAuthn(credId)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/tailwind-variables.scss';

.active {
  border-bottom-color: #ebebeb;
  color: #2f353a;
  .dark & {
    color: #ffffff;
  }
}
.inline-banner {
  margin-bottom: 0;
}
</style>
